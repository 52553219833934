<template>
  <div v-if="materials" class="padding-xy-0 padding-xy-5-tablet">
    <b-table
      :data="materials"
      class="scrollable clickable-row"
      :mobile-cards="false"
      :hoverable="true"
      @click="goToMaterial">
      <b-table-column v-slot="props" field="material_name" label="Material" width="100">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="gradeKsi"
        label="Ksi Old"
        width="50"
        style="min-width: 80px">
        {{ props.row.gradeKsi }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="gradeKsiLess"
        label="Ksi <= 4.5''"
        width="50"
        centered
        style="min-width: 80px">
        {{ props.row.gradeKsiLess }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="gradeKsiGreater"
        label="Ksi > 4.5''"
        width="50"
        centered
        style="min-width: 80px">
        {{ props.row.gradeKsiGreater }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="yieldStrengthMin"
        label="Yield Strength Min"
        width="50"
        style="min-width: 80px">
        {{ props.row.yieldStrengthMin }} psi
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="yieldStrengthMax"
        label="Yield Strength Max"
        width="50"
        style="min-width: 80px">
        {{ props.row.yieldStrengthMax }} psi
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="tensileStrengthMin"
        label="Tensile Strength Min"
        width="50"
        style="min-width: 80px">
        {{ props.row.tensileStrengthMin }} psi
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="compressiveStrength"
        label="Compressive Strength"
        width="50"
        style="min-width: 80px">
        {{ props.row.compressiveStrength }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="guaranteedWall"
        label="Guaranteed Wall"
        width="50"
        style="min-width: 80px">
        {{ (props.row.guaranteedWall * 100) }}%
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="chrome"
        label="Chrome"
        width="50"
        centered
        style="min-width: 80px">
        <b-icon v-if="props.row.chrome" size="is-small" icon="check" type="is-success" />
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'Materials',
  data () {
    const materialsColumns = [
      {
        field: 'material_name',
        label: 'Material'
      },
      {
        field: 'logo',
        label: 'Logo'
      },
      {
        field: 'icon',
        label: 'Icon'
      },
      {
        field: 'rendering',
        label: 'Rendering'
      }
    ]
    return {
      materialsColumns
    }
  },
  computed: {
    materials () {
      const materials = this.$store.state.connectionGrades
      const user = this.$store.state.profile
      if (user.hasJfeAdminPermission || user.hasJfeDataManagerPermission) {
        return materials
      } else {
        return materials.filter(material => material.public)
      }
    }
  },
  created () {
    this.$store.commit('setTitle', 'Materials')
    this.$store.dispatch('refreshConnectionGrades')
    this.$store.dispatch('refreshProfile')
  },
  methods: {
    goToMaterial (material) {
      this.$router.push({
        name: 'manage_material',
        params: { id: material.id }
      })
    }
  }
}
</script>
